<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Structure juridique > Super structure juridique" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une super structure juridique</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom complet</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- la structure juridique principale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">structure juridique principale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une structure juridique principale"
                    :items="availableMainLegalStructure"
                    item-text="digitalName"
                    no-data-text="aucune structure juridique"
                    v-model="mainLegalStructure"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- Les structures juridiques associés -->
            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les structures juridiques associées
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des structures juridiques à associé -->
                  <div v-for="(legal, i) in associateLegalStructure" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ legal.digitalName }}
                      </div>

                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateLegalStructure(legal)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des structures juridiques associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedLegalStructure"
                        :items="availableLegalStructure"
                        item-text="digitalName"
                        return-object
                        placeholder="Associer une structure juridique"
                        hint="Associer une structure juridique puis appuyer sur le bouton '+'"
                        clearable
                        no-data-text="aucune structure juridique"
                        v-on:keyup.enter="addLegalStructure(selectedLegalStructure)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addLegalStructure(selectedLegalStructure)"
                        :disabled="!selectedLegalStructure"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              outlined
              class="ma-2 px-4 btn"
              color="primary"
              :disabled="!canSave"
              @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { SuperLegalStructureService } from "@/service/etablishment/super_legal_structure_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import { trimString } from "@/tools/string_tool.js";

export default {
  name: "AddSuperLegalStructure",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      serviceLegalStructure: null,

      /** le nom digital */
      digitalName: null,
      /** le nom */
      name: null,
      /** la structure juridique principale sélectionnée */
      mainLegalStructure: null,

      /** La liste de toutes les super structures juridiques */
      allSuperLegalStructure: [],
      /** La liste des structure juridique principale sélectionnables */
      allAssociableLegalStructure: [],

      /** la liste des strutures juridiques associés */
      associateLegalStructure: [],

      /** la structure juridique sélectionné pour l'ajout */
      selectedLegalStructure: null,
    };
  },
  methods: {
    
    /** Chargement des données pour la vue */
    async load() {
      try {
        this.loading = false;

        // Récupération de la totalité des super structures juridiques
        this.allSuperLegalStructure = await this.service.getAll();
        /** Récupération de la liste des structure juridique */
        let allLegalStructure = await this.serviceLegalStructure.getAll();

        // Parcours de toutes les super structures juridiques pour récupérer tous les ids de structure juridique déjà associés
        let idsAssociates = [];
        this.allSuperLegalStructure.forEach((s) => {
            idsAssociates = idsAssociates.concat(s.legalStructures);
        });
        // Récupération de toutes les structures juridiques qui n'ont pas déjà été associés
        this.allAssociableLegalStructure = allLegalStructure.filter((l) => !idsAssociates.includes(l.id));


      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde des données */ 
    async save() {

      let success = true;

      // enregistrement de l'acronyme
      if (this.entityHasChanged) {
        try {
          let entity = {};
          entity.digitalName = this.digitalName;
          entity.name = this.name;
          entity.mainLegalStructure = this.mainLegalStructure.id;

          entity.legalStructures = [];
          entity.legalStructures.push(this.mainLegalStructure.id);

          // Récupération de tous les ids des structure juridique associés
          let ids = this.associateLegalStructure.map((l) => l.id);
          entity.legalStructures = entity.legalStructures.concat(ids);

          // création de la super structure juridique
          await this.service.create(entity);

        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la super structure juridique : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }

      if (success) {
        this.$router.go(-1);
      }
    },

    /** Suppression de la structure juridique de la liste des associés */
    removeAssociateLegalStructure(entity) {
      this.associateLegalStructure.splice(
        this.associateLegalStructure.findIndex((l) => l.id == entity.id),
        1
      );
    },

    /** Ajoute une structure juridique à la liste */
    addLegalStructure(entity) {
      if (!entity) return;

      this.associateLegalStructure.push(entity);
      this.selectedLegalStructure = null;
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.digitalName || this.digitalName == "") {
          return false;
        }
        if (!this.name || this.name == "") {
          return false;
        }
        if (!this.mainLegalStructure) {
          return false;
        }

        let changed = false;

        if (this.entityHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.digitalName) {
        changed = true;
      }
      if (this.name) {
        changed = true;
      }
      if (this.mainLegalStructure) {
        changed = true;
      }

      return changed;
    },   

    /** retourne la liste des structures juridiques non attribué à une super structure juridique */
    availableLegalStructure() {
      let availables = [];

      let ids = this.associateLegalStructure.map((e) => e.id);

      // si une structure juridique principale a été choisie, il faut la prendre en compte
      if (this.mainLegalStructure) {
        availables = this.allAssociableLegalStructure.filter((l) => !ids.includes(l.id) && l.id != this.mainLegalStructure.id);        
      } else {
        availables = this.allAssociableLegalStructure.filter((l) => !ids.includes(l.id));        
      }

      return availables;
    },
    /** Retourne la liste de toutes les structure juridique disponibles pour une structure juridique principale */
    availableMainLegalStructure() {
      let availables = [];

      let ids = this.associateLegalStructure.map((l) => l.id);

      availables = this.allAssociableLegalStructure.filter((l) => !ids.includes(l.id));

      return availables;
    },

  }, // END COMPUTED

  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new SuperLegalStructureService(
      this.$api.getSuperLegalStructureApi()
    );
    this.serviceLegalStructure = new LegalStructureService(this.$api.getLegalStructuresApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();

  },
};
</script>

<style>
</style>